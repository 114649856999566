import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { Image, Button, Menu } from "antd";
import {
  UserOutlined,
  LogoutOutlined,
  BarChartOutlined,
  ToolOutlined,
  CheckOutlined,
  ShoppingCartOutlined,
  ShopOutlined,
  AppstoreOutlined,
  GiftOutlined,
} from "@ant-design/icons";
import { useUser } from "providers/user/index";

import logo from "./logo.png";

const Sidebar = ({ history }) => {
  const { logout } = useUser();
  const [openKeys, setOpenKeys] = useState([]); // Estado para manejar los SubMenus abiertos

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => !openKeys.includes(key));
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  // Define los elementos del menú utilizando la nueva API
  const menuItems = [
    {
      key: "/dashboard",
      icon: <BarChartOutlined />,
      label: <Link to="/dashboard">Dashboard</Link>,
    },
    {
      key: "/sync-operations",
      icon: <ToolOutlined />,
      label: <Link to="/sync-operations">Operaciones</Link>,
    },
    {
      key: "/orders",
      icon: <CheckOutlined />,
      label: <Link to="/orders">Órdenes Completadas</Link>,
    },
    {
      key: "/abandoned",
      icon: <ShoppingCartOutlined />,
      label: <Link to="/abandoned">Carritos Abandonados</Link>,
    },
    {
      key: "/franchise",
      icon: <ShopOutlined />,
      label: <Link to="/franchise">Franquicias</Link>,
    },
    {
      key: "products",
      icon: <AppstoreOutlined />,
      label: "Productos",
      children: [
        { key: "/products", label: <Link to="/products">Productos</Link> },
        { key: "/landing", label: <Link to="/landing">Landing</Link> },
        {
          key: "/collaborations",
          label: <Link to="/collaborations">Artistas</Link>,
        },
        {
          key: "/categories/wellness",
          label: <Link to="/categories/wellness">Bienestar</Link>,
        },
        {
          key: "/categories/smoke",
          label: <Link to="/categories/smoke">Smoke</Link>,
        },
      ],
    },
    {
      key: "promotions",
      icon: <GiftOutlined />,
      label: "Promociones",
      children: [
        { key: "/banners", label: <Link to="/banners">Banners</Link> },
        { key: "/bundles", label: <Link to="/bundles">Bundles</Link> },
        { key: "/discounts", label: <Link to="/discounts">Discounts</Link> },
        {
          key: "/notifications",
          label: <Link to="/notifications">Notificaciones</Link>,
        },
      ],
    },
    {
      key: "users",
      icon: <UserOutlined />,
      label: "Usuarios",
      children: [
        { key: "/clients", label: <Link to="/clients">Clientes</Link> },
        { key: "/admins", label: <Link to="/admins">Admins</Link> },
        { key: "/employee", label: <Link to="/employee">Empleados</Link> },
      ],
    },
  ];

  return (
    <div style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      {/* Logo */}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          padding: 10,
        }}
      >
        <Image
          style={{
            margin: "0 auto",
            marginTop: 0,
            display: "block",
            paddingLeft: 10,
            paddingRight: 10,
          }}
          height={70}
          src={logo}
          alt="Paradise"
          preview={false}
        />
      </div>

      {/* Menú (scrollable) */}
      <div style={{ flex: 1, overflowY: "auto", paddingTop: 10 }}>
        <Menu
          style={{ marginTop: 10, background: "none" }}
          mode="inline"
          defaultSelectedKeys={[history.location.pathname.toLowerCase()]}
          selectedKeys={[history.location.pathname.toLowerCase()]}
          openKeys={openKeys} // Vincula el estado del SubMenu abierto
          onOpenChange={onOpenChange} // Maneja los cambios de apertura/cierre
          items={menuItems} // Pasa los elementos del menú
        />
      </div>

      {/* Botón de logout */}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          padding: 10,
        }}
      >
        <Button
          className="btnPrimary"
          style={{
            width: "100%",
          }}
          onClick={() => {
            logout();
          }}
        >
          <LogoutOutlined />
          Salir
        </Button>
      </div>
    </div>
  );
};

export default withRouter(Sidebar);
