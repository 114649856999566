import media from "./media";

const theme = {
  media,
  colors: {
    primary: "#2A9A3E",
    secondary: "#2A9A3E",
  },
};

export default theme;
