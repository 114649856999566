import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Layout as Layer, Card, Button, Drawer } from "antd";
import Sidebar from "layouts/main/sidebar";
import { MenuOutlined } from "@ant-design/icons";

const MainLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false); // Estado para colapsar el menú (opcional)
  const [isMobile, setIsMobile] = useState(false); // Estado para detectar pantallas pequeñas
  const [drawerVisible, setDrawerVisible] = useState(false); // Estado para el Drawer

  // Detecta el tamaño de la pantalla
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Cambia a true si el ancho es menor o igual a 768px
    };

    handleResize(); // Ejecuta en el primer render
    window.addEventListener("resize", handleResize); // Escucha cambios en el tamaño de la pantalla
    return () => window.removeEventListener("resize", handleResize); // Limpia el listener
  }, []);

  // Abre/cierra el Drawer
  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  return (
    <Layer style={{ minHeight: "100vh" }}>
      <Layer
        style={{
          background: "white",
          display: "flex",
          flexDirection: "row",
          height: "100vh",
          overflowY: "hidden",
        }}
      >
        {/* Botón de menú en pantallas pequeñas */}
        {isMobile && (
          <Button
            icon={<MenuOutlined />}
            type="secondary"
            style={{
              position: "fixed",
              top: 10,
              left: 10,
              zIndex: 1000,
            }}
            onClick={toggleDrawer}
          />
        )}

        {/* Sidebar en pantallas grandes */}
        {!isMobile && (
          <Sidebar collapsed={collapsed} onCollapse={setCollapsed} />
        )}

        {/* Drawer en pantallas pequeñas */}
        {isMobile && (
          <Drawer
            title="Menú"
            placement="left"
            closable={true}
            onClose={toggleDrawer}
            open={drawerVisible}
            bodyStyle={{
              padding: 0,
              overflowY: "auto", // Habilita scroll vertical
              maxHeight: "100vh", // Asegura que no desborde el viewport
            }}
          >
            <Sidebar collapsed={collapsed} onCollapse={setCollapsed} />
          </Drawer>
        )}

        {/* Contenido principal */}
        <Card
          style={{
            flex: 1, // Ajusta dinámicamente al espacio disponible
            height: "100vh", // Ocupa el alto completo
            overflowY: "auto", // Habilita scroll si el contenido es largo
            borderRadius: 0,
            background: "#F0F0F0",
          }}
        >
          {children}
        </Card>
      </Layer>
    </Layer>
  );
};

MainLayout.propTypes = {
  children: PropTypes.any.isRequired,
};

export default MainLayout;
